.grid_checks {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}

.col_numbering label {
    width: 380px;
    font-size: 22px;
}

#user_settings_model .note-modal-dialog {
    max-width: 60% !important;
}

li.user_setting_default_btn {
    background: #4682b47a;
    padding: 7px 13px;
    border-radius: 5px;
}

#user_settings_model label.form-check-label {
    margin-left: 14px;
    font-size: 20px;
}

#user_settings_model input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
    width: 30px;
    height: 20px;
    margin-right: 13px;
}

