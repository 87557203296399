.login_form {
    min-height: calc(100vh - 38px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.login_form::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    background-image: url('../../../images/loginBackgroud.jpeg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(2px);
}

.login_form::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    background-color: rgb(0 0 0 / 40%);
}

.mfa_form::before {
    background: white none !important;
}

.log_in {
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 50px 50px;
    border-radius: 15px;
}

S.mfa_form .card{

    padding: 70px 70px;
}
.mfa_form {
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 50px 50px;
    border-radius: 15px;
    color: #212529;
}


.log_in .form-control {

    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    /*border-radius: 35px;*/
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: #000;

}

.log_in label {
    color: #fff;
}

.log_in .form-control::placeholder {
    color: #000;
}

/*.log_in .form-group {*/
/*    margin-bottom: 1.5rem;*/
/*}*/

.log_in h1 {
    color: #fff;
    text-align: center;
    margin-bottom: 35px;
}

.log_in button {
    background: #000;
    color: #fff;
    display: inline-block;
    border: transparent;
    padding: 10px 40px;
    margin-top: 15px;
    border-radius: 35px;
    transition: ease all .3s;
    -webkit-transition: ease all .3s;
    position: relative;
}

.log_in form {
    border-radius: 15px;
}

.form_logo img {
    width: 100%;
}

.form_logo {
    text-align: center;
    margin-bottom: 15px;
}

.pass_recall a:hover {
    color: #0081ef;
    transition: ease all .3s;
    -webkit-transition: ease all .3s;
}

.log_in button:hover {
    background: #437fb2;
    transition: ease all .3s;
    -webkit-transition: ease all .3s;
}

.pass_recall a {
    color: #fff;
    transition: ease all .3s;
    -webkit-transition: ease all .3s;
}

.pass_recall {
    color: #eeeeee;
}

.pass_recall:hover {
    color: #437fb2;
}

h3.site_title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    position: relative;
    z-index: 2;
    color: #000;
}

ul.header_links {
    position: absolute;
    top: 0;
    right: 0;
    padding: 9px;
    z-index: 2;
    background: #000;
    width: 100%;
    text-align: right;
}

ul.header_links li a {
    padding: 0 20px;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #437fb2;
    border-right-color: #e7eae7;
    animation: spinner 1.0s linear infinite;
    right: 12px;
    top: 11px;
}


.resware_password1 {
    position: relative;
}

.resware_password1 i {
    position: absolute;
    right: 16px;
    top: 11px;
}

.login_page_link:hover {
    color: #0056b3 !important;
    text-decoration: underline;
}

button.list_btn {
    display: flex;
    gap: 15px;
    align-items: center;
    font-size: 18px;
    border: unset;
    padding: 15px 10px;
    font-weight: 500;
    background: transparent;
    width: 100% ;
}

button.list_btn i {
    font-size: 40px;
    color: #4782b4;
}
.mf_card img {
    max-width: 300px;
    height: auto;
}
.mfa_form button.list_btn:focus {
   background: #f0f0f0;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.list_btn.preferred_device {
    background-color: #f0f0f0; /* Light gray background */
    border-color: #d0d0d0;    /* Slightly darker border */
    color: #555;              /* Darker text for better visibility */
}

.list_btn.preferred_device.selected {
    background-color: #dcdcdc; /* Slightly darker gray when selected */
    border-color: #a0a0a0;
}
