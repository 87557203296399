.font_8 {
    text-align: justify;
    font-size: 16px;
}

.bottom_font{
    text-decoration: underline;
}


.info_model_bold {
    font-weight: bold;
}
.info_model_span_dc{
    text-decoration:underline;
}

ul.font_8.wixui-rich-text__text li {
    list-style: initial;
}

.modal_body_height{
    max-height: 70vh;
    overflow: auto;
}

.center_span{
    text-align:center; font-size:17px;
}

.info_model_text{
    color:#000000;
}