.form_design {
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 20%);
    border-radius: 7px;
    background: #fff;
}

.margin_form {
    margin-left: 6px;
    margin-right: 6px;
    border-radius: 1px !important;
}

.form_header h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}


.form_header.bg {
    background: #4682b4;
    padding: 8px 10px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.form_header.bg h2 {
    color: #fff;
    margin: 0;
}

.bg_form_outer {
    box-shadow: 0 0 5px rgba(0, 0, 0, 20%);
    border-radius: 7px;
    background: #fff;
}

.non-required {
    padding-left: 6px;
}

.react-date-picker--disabled {
    background-color: #e9ecef !important;
    color: #6d6d6d !important;
}

.check-box{
    width: 18px;
    height: 18px;
    margin-top: 8px;

}

.search_grid_search_icon {
    position: absolute;
    bottom: 8px;
    right: 16px;
}
