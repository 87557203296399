.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.notification span.ml-auto.mt-2 .showNotification i {
    color: black;
    margin-right: 12px;
    font-size: 22px;
}

.jurisdictions_search_bar {
    width: 270px;
    max-width: 270px;
}

.search-span {
    float: right;
    margin-right: 6px;
    margin-top: -27px;
    position: relative;
    z-index: 2;
    color: #3f627e;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.header {
    background: #000;
    padding: 5px;
    /*border-bottom: 4px solid #3f627e;*/
}

.sidenav {
    height: 100vh;
    width: 200px;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #3f627e;
    overflow-x: hidden;
    padding-top: 0;
}

.sidenav a {
    padding: 6px 6px 6px 32px;
    text-decoration: none;
    font-size: 15px;
    color: #f1f1f1;
    display: block;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.main {
    margin-left: 200px; /* Same as the width of the sidenav */
}

@media screen and (max-height: 450px) {
    .sidenav a {
        font-size: 18px;
    }
}

.homeDiv {
    position: absolute;
    left: 28%;
}

.table-header {
    background: #1f6eae;
    color: aliceblue;
    font-size: 13px;
}

.navbar-brand {
    padding-top: 0 !important;
}

.breadcrumb {
    background-color: #fff0 !important;
    padding-left: 0;
    margin: 0;
    padding-top: 0;
}

.soft-shadow {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11),
    2px 2px 2px rgba(0, 0, 0, 0.11),
    4px 4px 4px rgba(0, 0, 0, 0.11),
    6px 6px 8px rgba(0, 0, 0, 0.11),
    8px 8px 16px rgba(0, 0, 0, 0.11);
}

ol.breadcrumb button {
    padding-left: 0;
}

.main_content {
    min-height: 95vh;
    overflow: auto;
}

/*table css*/

.table_data thead {
    background: #4682b4;
    color: #fff;
    position: relative;
}

.table_sticky_header {
    position: sticky;
    position: -webkit-sticky;
    top: 53px;
    background: #4682b4;
}

section#content-wrapper {
    min-height: calc(100vh - 38px);
    position: relative;
    padding-top: 60px;
}

/* pagination */
a.cdp_i {
    font-size: 13px;
    text-decoration: none;
    transition: background 250ms;
    display: inline-block;
    text-transform: uppercase;
    margin: 0 3px 6px;
    height: 30px;
    min-width: 30px;
    line-height: 30px;
    padding: 0;
    color: #000;
    font-weight: 700;
    letter-spacing: 0.03em;
    border-radius: 50%;
    box-shadow: 0 0 6px rgba(0, 0, 0, .3);
    text-align: center;
    -webkit-transition: ease all 0.7s;
}

.cdp_i:hover,
.cdp_i.active {
    background: #4682b4;
    color: #fff !important;
    transition: ease all 0.7s;
    -webkit-transition: ease all 0.5s;
}

.table_data td {
    color: #000;
}

.toggled i.fa.fa-angle-double-right::before {
    content: "\f100";
    transition: all ease 2s;
}

.table_head ul {
    padding: 7px 0;
    margin: 0;
    justify-content: flex-end;
    align-items: center;
    background: #4682b4;
    display: inline-block;
    position: absolute;
    top: 3px;
    right: 15px;
    font-size: 14px;
}

.table_data .table-bordered th {
    border: unset;
}

table th {
    white-space: nowrap;
}

.table_head li {
    margin: 0;
    display: inline-block;
}

.table_head {
    text-align: right;
}

.table_data .table-responsive > .table-bordered {
    border: 1px solid #ddd;
}

li.add_users {
    color: #fff;
    padding: 0 20px;
}

li.grid_search {
    border-left: black;
}

li.search_users input {
    width: 310px;
}

.pagination_outer {
    display: flex;
}

.content_detail__pagination.cdp {
    flex-grow: 1;
}

.anchor-btn {
    color: white !important;
    text-decoration: none !important;
}

li.modal_data {
    color: #fff;
    padding: 0 11px;
}

.pages_details a {
    margin: 0 5px;
}

li {
    list-style: none;
}

.user_label {
    flex-grow: 1;
    text-align: left;
}

.table_head .user_label ul {
    border-top-left-radius: unset;
    border-top-right-radius: 10px;
}

.enabled {
    margin-left: 30px;
    font-size: 17px;
}

.disabled-data {
    color: #144A88;
}

.enabled-data {
    color: green;
}

.cm_btn {
    padding: 5px 14px;
    background: #4682b4;
    color: #fff;
    border: transparent;
    border-radius: 11px;
    margin: 7px 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);
}

.cm_btn.cm_bg {
    background: #ddd;
    color: #000;
}

.toggle-data-header {
    color: white;
    text-decoration: none;
}

a.toggle-data-header:link, a.toggle-data-header:visited {
    color: white;
    text-decoration: none;
}

a.toggle-data-header:hover, a.toggle-data-header:active {
    color: #81d5ff;
}

.toggle-data-header.active {
    color: #80d5ff !important;
    font-weight: bold;
}

.grid-color {
    color: white;
    cursor: default;
}

a.grid-color:hover, a.grid-color:active {
    color: white;
    cursor: default;
}

a.column-link:link, a.column-link:visited {
    color: white;
    text-decoration: none;
}


.column-link.active {
    color: #007bff !important;
}


a.column-link:hover, a.column-link:active {
    color: #026bde;
}

.cursor {
    cursor: pointer;
}

.disabled-cursor {
    cursor: not-allowed;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@keyframes loading {
    40% {
        background-position: 100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.loading {
    position: relative;
}

.loading .bar {
    background-color: #e7e7e7;
    height: 24px;
    border-radius: 7px;
    width: 80%;
}

.loading:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 24px;
    background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 80%);
    background-size: 200px 24px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 2s infinite;
}

.btn-radius {
    border-radius: 11px;
}

/*loader css*/
.preloader {
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
}

.preloader img {
    height: 200px;
}

div.customModal::after {
    position: fixed;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0000006e;
    z-index: -1;
}

.form-control::placeholder {
    color: #97a0a0;
}

.breadcrumb-item + .breadcrumb-item::before {
    padding-left: 0 !important;
}

.text_wrap {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search_bar {
    border-radius: 2px;
    width: 295px;
    background: #e5ebef;
    padding-left: 5px;
}

.theme_bg {
    background: #4682b4;
}

.tax-table {
    width: 100%;
}

table.scroll1 td {
    border: 1px solid #d1c9c9;
    padding: 6px;
    text-align: left;
}

table.scroll1 tr:nth-child(even) {
    background-color: #f2f2f2f5;
}

.breadcrumb_padding {
    padding-bottom: 2px;
}

.group-box {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 0.5em 0.5em;
}


/*search and add btn css*/
.table_head ul.btn-radius.grid_search {
    right: 75px;
}

/*search and add btn css*/
.table_head ul.btn-radius.grid_search {
    right: 75px;
}

.dropdown-menu.show label {
    padding-left: 0.3em;
}

.order_form input {
    display: inline !important;
}

/*.dropdown-menu span {*/
/*    padding-left: 15px*/
/*}*/

.table_data.customer_pricing td {
    color: #000;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.table_data1 {
    height: 38px;
}

.update-modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.selected_row {
    background: rgb(128, 189, 255) !important;
}


.selected_row_result_history_old {
    background: rgb(125, 56, 63) !important;
    opacity: 0.9;

}


.selected_row_result_history_new {
    background: rgba(35, 99, 104, 1) !important;
    opacity: 0.9;
}

.selected_row_hoa {
    background: #87c287 !important;
}

.nav-tabs {
    margin-bottom: 0 !important;
}

/*29 april*/
.bootstrap-tabs-component ul {
    margin-bottom: 0 !important;

}

.disable_add_btn {
    background: #969da4 !important;
    color: white !important;
}

/**/
.delete_btn_center_align {
    width: 58px;
    text-align: center;
}

/**/
.icon_align_center {
    margin-left: 35%;
    text-align: center;
}


.fa_icon_btn_center_align {
    width: 43px;
    text-align: center;
}

.table_data td .fa_icon_btn_center_align a {
    font-weight: 900 !important;
}

/*sucess spinner*/
.btn_success_spinner {
    position: relative;
    padding-right: 40px;
}

.btn_success_spinner span {
    top: 7px;
}

.table tr {
    border-top: 1px solid #dee2e6;
}

.table td {
    border-top: none !important;
}

thead.detail_page_header {
    border-bottom: 3px solid #dee2e6 !important;
}

.position-absolute {
    position: absolute !important;
    margin: 130px 190px 190px 15%;

}

.job_status_div {
    text-align: center;
}

.sys_Created_hoa {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}

.table_head ul.btn-radius.user_settings_cog {
    right: 138px;
}


.table_head ul.btn-radius.user_settings_model {
    right: 379px;
    top: auto;
    background: #fff;
}

.team_work .table_head ul.btn-radius.user_settings_model_without_pagination {
    bottom: 8px;
}

.table_head ul.btn-radius.user_settings_model_without_pagination {
    bottom: -33px;
    right: 30px;
    top: auto;
    background: #ffff;

}

.table_head ul.btn-radius.user_settings_model i,
.table_head ul.btn-radius.user_settings_model_without_pagination i {
    color: #000;
    font-size: 24px;
}

.disable_mgmt_icon {
    pointer-events: none;
    color: #8a8080;;

}

.modal-content.sub_div_group_main_form {
    width: 50% !important;
}

.suporting_modules {
    border: 1px solid #ddd;
    min-height: 40px;
    padding: 10px 40px 10px 10px;
    position: relative;
}

.suporting_modules .search_span {
    position: absolute;
    right: 5px;
    top: 15px;
}

.suporting_modules span {
    padding: 5px 7px;
    display: inline-block;
}

.edit_icon span {
    align-items: center;
    background: #4682b4;
    display: inline-block;
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 11px;
    color: #fff;
}

.edit_disable_icon span {
    align-items: center;
    background: #84898e;
    display: inline-block;
    font-size: 14px;
    padding: 7px 20px;
    border-radius: 11px;
    color: #fff;
}

.edit_icon {
    position: absolute;
    right: 0;
    top: -45px;
}

.edit_disable_icon {
    position: absolute;
    right: 0;
    top: -45px;
}

.css-14el2xx-placeholder {
    color: rgb(151 160 160) !important;
}

.form-control-number {
    padding: 0 !important;
}

.disable_the_btn {
    cursor: not-allowed !important;
    pointer-events: none !important;
    color: #9d9292 !important;
}


.active_the_btn {
    color: #4682b4 !important;
}

i.fa.fa-arrows {
    font-family: "FontAwesome";
    font-size: 24px;
}

button.form-control.canned_modal_active_tabs {
    background: #4682b4;
    border: transparent;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    color: #fff;
}

button.form-control.canned_modal_hoa_active_tabs {
    background: #407b40;
    border: transparent;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    color: #fff;
}

button.form-control.canned_modal_in_active_tabs {
    background: #ebeef1;
    border: transparent;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    color: #000000;
}

.green_bg {
    background-color: #407b40 !important;
    color: #fff !important;
}

.detail-style {
    background: #f9f9f9;
    margin-left: 0;
    margin-right: 0;
    border-radius: 7px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.dropdown-menu.show {
    height: 100px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(215, 215, 215);
    overflow-y: auto;
    padding: 0 0.5em;
}

.dropdown-menu.show label {
    padding-left: 0.3em;
}

.auto_deliver_btn {
    color: #495057;
    background-color: #ffffff;
    width: 100%;
    text-align: left;
    border-color: #ced4da;
}

.btn-secondary:hover {
    color: #495057 !important;
    background-color: transparent !important;
    border-color: #ced4da !important;
}

.dropdown-toggle:after {
    top: 0.7em;
}

.text-wrap {
    white-space: nowrap;
    /*width: 51em;*/
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-section {
    border-style: solid;
    border-width: 2px;
    border-color: #e5ebef;
    border-radius: 7px;
}

.table-background {
    background: #e5ebef;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #4682b4 !important;
    border-color: #4682b4 #4682b4 #4682b4 !important;
}

hr {
    margin-top: 0 !important;
    margin-bottom: 6px !important;
}

.nav-tabs {
    margin-bottom: 0 !important;
}

.setting_margin {
    margin-bottom: 0 !important;
}

.customer-modal-dialog {
    max-width: 781px !important;
}

.group_main_form fieldset {
    min-width: unset;
    padding: 2px 10px;
    margin: 0;
    border: 2px solid #4682b4;
}

.group_main_form legend {
    width: auto;
}

.advance_settings i {
    transform: rotate(
            90deg);
}

/*media*/
@media screen and (max-width: 1199px) {
    #content-wrapper .from-group b {
        font-weight: 400;
        font-size: 13px;
    }

    section#content-wrapper h5 {
        font-size: 16px;
    }

    .table_data th a {
        font-weight: 500;
        font-size: 14px;
    }

    .table_data tbody td {
        font-size: 14px;
    }

    .from-group label {
        font-size: 14px;
    }

    .breadcrumb_outer li button {
        font-size: 14px;
    }

    .breadcrumb_outer li span {
        font-size: 14px;
    }
}

.file-field {
    padding: 3px 10px;
    font-size: 14px;
    height: 34px;
}

/****** toggle button ************/
.knobs, .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.button {
    position: relative;
    top: 50%;
    width: 50px;
    height: 31px;
    overflow: hidden;
}

.button.r, .button.r .layer {
    border-radius: 100px;
}

.button.b2 {
    border-radius: 2px;
}

.checkbox-for-switch {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.knobs {
    z-index: 2;
}

.layer {
    width: 100%;
    background-color: rgb(84 87 89 / 44%);
    transition: 0.3s ease all;
    z-index: 1;
}

/* Button 1 */
.button-1 .knobs:before {
    background-color: #6c6565;
    border-radius: 50%;
    color: #fff;
    content: "NO";
    font-size: 8px;
    font-weight: 700;
    height: 25px;
    left: 3px;
    line-height: 1;
    padding: 9px 4px;
    position: absolute;
    text-align: center;
    top: 3px;
    transition: all .3s cubic-bezier(.18, .89, .35, 1.15);
    width: 25px;
}

.button-1 .checkbox-for-switch:checked + .knobs:before {
    background-color: #03a9f4;
    content: "YES";
    left: 21px;
}

.button-1 .checkbox-for-switch:checked ~ .layer {
    background-color: #b3dbec;
}

.button-1 .knobs, .button-1 .knobs:before, .button-1 .layer {
    transition: 0.3s ease all;
}

.custom-style {
    top: 55px !important;
}

.big-checkbox {
    width: 20px;
    height: 20px;
}

.label-color {
    cursor: pointer;
    color: #007bff !important;
}

.user_detail.reset_btn li {
    display: inline;
}

.bold {
    font-weight: bold;
}

.cm-header {
    background: #437fb2;
    padding: 10px;
    color: #fff;
}

.cm-header h3 {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
}

.USer_amin_form_outer .USer_amin_form {

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.detail_header {
    display: flex;
    width: 100%;
    border-bottom: 3px solid #144a88;
}

.user_detail {
    background: #437fb2;
    padding: 12px 20px;
}

.user_detail.details_btn {
    border-top-right-radius: 15px;
}

.user_detail.reset_btn {
    margin-left: auto;
    border-top-left-radius: 17px;
}

.user_detail a {
    color: #fff;
}

.USer_amin_form_outer {
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    border-radius: 7px;
    background: #fff;
}

.USer_amin_form {
    padding: 20px;

}

div.user_detail a:not([href]):not([tabindex]) {
    color: #fff;
    cursor: pointer;
}

.error_cl {
    color: red;
    margin-top: 6px;
    margin-left: 2px;
}

/*tab 1*/
.active_tab1 h3 {
    background: #4682b4;
}

.active_tab1 h3:after {
    border-left: 14px solid rgb(70 130 180);
}

.inactive_tab1 h3 {
    background: #969da4;
}

.inactive_tab1 h3:after {
    border-left: 14px solid rgb(150 157 164);
}

/*tab 2*/

.active_tab2 {
    background: #4682b4;
}

.active_tab2 h3:before {
    background: #4682b4;
}

.active_tab2 h3:after {
    border-left: 14px solid rgb(70 130 180);
}


.inactive_tab2 {
    background: #969da4;
}

.inactive_tab2 h3:before {
    background: #969da4;
}

.inactive_tab2 h3:after {
    border-left: 14px solid rgb(150 157 164);
}

/*tab 3*/
.active_tab3 h3 {
    background: #4682b4;
}

.active_tab3 h3:before {
    border-left: #4682b4;
}

.inactive_tab3 h3 {
    background: #969da4;
}

.inactive_tab3 h3:before {
    border-left: #969da4;
}

.tab_head h3 {
    color: #fff;
    padding: 10px;
    position: relative;
    font-size: 16px;
}


.tab_head h3::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-bottom: solid 20px transparent;
    border-top: solid 20px transparent;
    right: -14px;
    top: 0;
}

.tab_head h3::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: solid 14px #fff;
    border-bottom: solid 19px transparent;
    border-top: solid 19px transparent;
    left: 0;
    top: 0;
}

.tab_head_outer:nth-child(1) .tab_head h3::before, .tab_head_outer:nth-child(3) .tab_head h3::after {
    content: unset;
}

.tab_head_outer:nth-child(2) .tab_head h3, .tab_head_outer:nth-child(3) .tab_head h3 {
    padding-left: 30px;
}

[data-toggle="collapse"] .fa:before {
    content: "\f078";
}

[data-toggle="collapse"].collapsed .fa:before {
    content: "\f054";
}


.collapse_form .collapse_head {
    background-color: #4682b4;
}

.Account_form .Account_head {
    background-color: #4682b4;
}

.collapse_form .collapse_head button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 1.1rem;
    color: #fff;
    position: relative;
}

.custom-collapse {
    float: right;
    position: absolute;
    right: 26px;
    top: -5px;
}

button.custom-collapse.custom-btn_detail.address_color {
    background: transparent;
}

.Account_form .Account_head button {
    display: block;
    width: 100%;
    text-align: left;
    font-size: 1.1rem;
    color: #fff;
    position: relative;
}

.collapse_form .collapse_head button i {
    position: absolute;
    right: 50px;
    top: 7px;
    font-size: 16px;

}

.Account_form .Account_head button i {
    position: absolute;
    right: 50px;
    top: 7px;
    font-size: 16px;

}

.del_btn {
    position: absolute;
    right: 15px;
    top: 3px;
}

.del_btn i {
    color: #fff;
    font-size: 15px;
}

.custom-btn {
    background: steelblue;
    border: none;
    height: 33px
}

.order_info_inner {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
}

.order_info_inner p.label {
    font-weight: 600;
    color: #144a88;
    font-size: 14px;
    margin: 0;
}

.order_ate_outer {
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 30%);
    border-radius: 7px;
    background: #f1f1f1;
}

.order_ate_outer th, .order_ate_outer td {
    text-align: center;
}

.order_ate_outer th {
    background-color: #4682b4;
    color: #fff;
}

/* 9 dec */
.btn_outer {
    display: flex;
}

.table-responsive.finishing_table th, .table-responsive.finishing_table td {
    padding: 5px;
}

.order_ate_outer tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.order_ate_outer tbody {
    display: block;
    max-height: 180px;
    overflow: auto;
}

.table_td1 {
    width: 7%;
}

.table_td2 {
    width: 17%;
}

.row_border {
    border-bottom-style: solid;
    border-color: #ced3d7;
    border-width: 1px;
}

.fa-icon-color {
    color: #4682b4;
}

.with-max-h {
    max-height: 68px;
    overflow-y: auto;
    overflow-x: hidden;
}

.with-max-d {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
}

.with-max-document {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
}

.card-padding {
    padding: 20px;
}

.order_header {
    display: flex;
}

.order_detail_show {
    padding-right: 14px;
}

.arrow_buttons {
    position: absolute;
    right: 0;
    top: 0;
}


.tax_selection select {
    height: 30px;
    border-radius: 7px;
    padding: 0 11px;
    border-color: #bec2c4;
}

.accordion_background {
    background: white;
}

.bottom-data div {
    border-bottom: 1px solid #ddd;
    padding: 7px;
    width: 100%;
}

.collapse_form.panel-group .collapse_head button i {
    right: 11px;
}

.custom-btn_detail {
    background: #e5ebef;
    border: none;
    min-height: 33px;
}

.custom-btn_detail_cert_editor {
    background: #d4a8ff !important;
    border: none;
    height: 33px;
}

.address_color {
    color: #4682b4 !important;
}

.address_color_cert_editor {
    color: #cc99ff !important;
}


.detail-style .media i {
    font-size: 20px;
    margin-right: 9px;
}

.detail-style .media h5 {
    margin-bottom: 2px;
    font-size: 16px;
    position: relative;
}

.detail-style .media p {
    font-size: 14px;
    position: relative;
}

.detail-style .media {
    align-items: center;
    background: #fff;
    padding: 8px;
    border-radius: 7px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 30%);
    margin: 5px 10px;
    position: relative;

}

.detail-style .media-body {
    text-align: center;
}

/*.product_card {*/
/*    overflow-x: auto;*/
/*}*/

.arrow_buttons {
    position: absolute;
    right: 0;
    top: 0;
}

button.arrow_down {
    background: #ff9800;
    padding: 10px 10px;
    display: inline-block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    border-radius: 0;
    border: unset;
}

button.arrow_down::before {
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-top: 16px solid #ff9800;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -15px;
}

button.arrow_down2 {
    background: #ffc107;
    padding: 10px 10px;
    display: inline-block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    border-radius: 0;
    border: unset;
}

button.arrow_down2::before {
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-top: 16px solid #ffc107;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -15px;
}

button.arrow_down3 {
    background: #07c5ff;
    padding: 10px 10px;
    display: inline-block;
    color: black;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    border-radius: 0;
    border: unset;
}

button.arrow_down3::before {
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
    border-top: 16px solid #07c5ff;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -15px;
}

.custom_margin {
    margin-top: -13px;
}

.note-modal-dialog {
    max-width: 100vh !important;
}

.multi-order-modal-dialog {
    max-width: 140vh !important;
}

.document-modal-dialog {
    max-width: 137vh !important;
}

.lock-modal-dialog {
    max-width: 65vh !important;
}

.img_style {
    width: 41px;
}

.cursor {
    cursor: pointer;
}

.text-color {
    color: steelblue;
}

.error_style {
    color: #495057;
    background-color: #fff;
    border-color: #ff8092;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(255 0 12 / 25%);
}

.show_event {
    display: block;
}

.hide_event {
    display: none;
}

.editable {
    color: #007bff;
    text-decoration: none !important;
}

.header_width {
    width: 57%;
}

.textarea-data {
    height: 385px !important;
}

.info_inner {
    display: flex;
}

.info_inner .input-group {
    width: auto;
    align-items: center;
    margin-right: 10px;
}

.info_inner {
    display: flex;
}

.top_section input, .top_section select {
    border-radius: 7px;
    border: 1px solid #ddd;
    margin-left: 7px;
    padding: 3px 10px;
}

.top_section label {
    margin: 0;
}

.top_section select {
    height: 30px !important;
    padding: 0 14px;
    min-width: 203px;
}

.product_status_circle {
    font-size: 10px;
    margin-right: 3px;
}


.role_sel_users_inner {
    position: absolute;
    width: 100%;
    border: 1px solid #ddd;
    padding: 6px 10px;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 150px;
    font-size: 16px;
    color: #495057;
}


.role_sel_users {
    position: relative;
}

.nav-container-bg {
    background: #4682b4 !important;
    border-color: white !important;

}

.scroll-data {
    max-height: 315px !important;
    overflow-y: scroll;
    display: block;
}

tr.tr-data {
    display: block;
}

.disable_assign_to_btn {
    pointer-events: none;
    color: white !important;
}

.link-data {
    text-decoration: underline;
    color: #157af6;
}

a:not([href]):not([tabindex]).active_assignedTo.cursor_assignedTo {
    color: steelblue;
    cursor: pointer;
}

tr.background_effect {
    background-color: #a1d7ff !important;
}

.assigned_to_active_cursor {
    cursor: pointer;
}

span.badge.badge-info {
    font-size: 8px;
}

span.badge.badge-primary {
    font-size: 10px;
}

.cert_note {
    border: 1px solid #d0d2d5;
    background: rgb(233, 236, 239);
}

.hide {
    display: none !important;
}

.modal_label {
    width: 27px;
    height: 16px;
}

.label_size {
    font-size: 18px;
}

.margin_bottom_custom {
    margin-bottom: unset !important;
}

.value {
    margin-top: 12px !important;
}

.order_detail_pricing_section.show {
    border-top: 1px solid #ddd;
}


.assign_to_btn {
    border-radius: 6px;
    border: none;
}

.side_bar_table table {
    table-layout: fixed;
    width: 100%;
}

.side_bar_table span.badge.badge-primary {
    font-size: 13px;
}

.tax_data {
    display: inline-block;
    margin-right: 20px;
}

.similar_table_height {
    height: 250px;
}

span.badge.badge-success {
    font-size: 10px;
}


.x-auto {
    overflow-y: unset;
    overflow-x: unset;
}

.resolve-span {
    font-size: 12px !important;
    background: cadetblue;
}

.resolved {
    background: #ddd;

}

.search_span {
    float: right;
    margin-right: 6px;
    margin-top: -26px;
    position: relative;
    z-index: 2;
    color: #4682b4;
}

div#accordion .card {
    border: transparent;
    margin-bottom: 5px;
}


div#accordion .card-header {
    padding: 0;
    background: #4682b4;
    color: #fff;
    border-radius: unset;
    margin: 0 5px;
}

div#accordion .card-header button {
    color: #fff;
    display: block;
    width: 100%;
    text-align: left;
}

div#accordion .card-header i {
    position: absolute;
    right: 20px;
    top: 10px;
}

button:focus {
    outline: 0 !important;
}

.edit_icon.export {
    right: 65px;
}

.edit_disable_icon.export {
    right: 65px;
}

.edit_property {
    position: absolute;
    right: 50px;
    z-index: 2;
    top: 7px;
    cursor: pointer;
    color: steelblue;
}

.place_holder_data::placeholder {
    color: #494f57;
}

.select__menu {
    z-index: 5;
}

.second_toast_message {
    margin-top: 10px !important;
}

body .detail-style .select__menu {
    z-index: 5;
}

.dropdown.notification .dropdown-menu.show {
    background: #fff;
    height: auto;
    width: 100%;
    min-width: 500px;
    left: auto;
    right: 0;
    padding: 0;
    border: 1px solid #ddd;
    box-shadow: 0 0 18px RGB(0 0 0 / 20%);
}

.notification_header {
    padding: 10px;
    border-bottom: 1px solid #fff;
}

.right_nav .dropdown.notification ul li:last-child {
    border-bottom: unset;
}

.right_nav .dropdown.notification ul {
    display: block;
    padding: 0;
}

.right_nav .dropdown.notification ul li {
    display: flex;
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid #fff;
}

.with-max-notification {
    max-height: 632px;
    overflow-x: hidden;
    overflow-y: auto;
}

.right_nav .dropdown.notification ul li p {
    margin: 0;
}

.right_nav .dropdown.notification ul li i {
    color: #000;
    margin-right: 11px;
}

.unread_notification {
    background: white;
}

.read_notification {
    background: #f0f0f0;
}

.notification button.btn.btn-secondary.dropdown-toggle {
    padding: 4px;
    background: transparent;
    position: relative;
    border: transparent;
}

.notification button.btn.btn-secondary.dropdown-toggle span {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: #00adf5;
    border-radius: 50%;
    color: #fff;
    line-height: 19px;
    position: absolute;
    top: 0;
    right: 12px;
    text-align: center;
}

.notification button.btn.btn-secondary.dropdown-toggle::after {
    color: #fff;
}

.dropdown.notification i {
    font-size: 30px;
}

.dropdown.notification {
    padding-left: 18px;
}

.notification_data {
    color: gray;
    font-size: 12px;
}

.resize {
    resize: none !important;
}


.collector_detail_module_search_span {
    color: #4682b4;
    float: right;
    margin-right: 6px;
    margin-top: -26px;
    position: relative;
    z-index: 2;
}

.icon_size {
    font-size: 15px !important;
}

.right_nav .dropdown.notification ul li:hover {
    background: #ddd;
}

.line_break {
    word-break: keep-all;
    width: 200px;
    min-height: 35px;
    height: 100%;
}

.fa_icon_read {
    color: #01adf5 !important;
}

.fa_icon_unread {
    opacity: 0.5;
    pointer-events: none;
}

.notification_top {
    border-top: 1px solid gainsboro;
}

.error {
    color: red;
}

.readOnly {
    background: #edebeb;

}

.disabled_notification {
    color: gray;
}

.icon_data {
    font-size: 22px !important;
}

.badge.bg-secondary {
    background: #4682b4 !important;
    color: #fff;
    margin-bottom: 4px !important;
}

button.border-0.bg-transparent svg {
    fill: #fff !important;
}

input.input-tags:focus-visible {
    outline: unset;
}

/*2 may custom multiselect*/
.custom_multi_select_input + div {
    background: red;
}

.custom_multi_select_input > div {
    background: #e6e6e6;
    margin-right: 2px;
    margin-bottom: 2px;
    padding: 4px;
    border-radius: 7px;
    color: black;
    cursor: pointer;
}

.custom_multi_select_input {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 2px;
}

.custom_multi_select_input input:focus-visible {
    outline: unset;
}

.custom_multi_select_input input {
    border: transparent;
}

.custom_multi_select_input .input_delete {
    border: 1px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    line-height: 17px;
    margin-left: 5px;
    font-size: 14px;
}

.yellowHighLight {
    background: #ffff80;
}

.red_text {
    color: red !important;
}

.property_result_source_cert {
    color: #cc99ff !important;
}

.bold {
    font-weight: 800 !important;
}

.password_style {
    z-index: 0 !important;
}

.disableSelection {
    color: gray;
}

.text_color {
    color: white;
}

.disabled_button {
    background: #9b9da0;
}

.background_data {
    background: red !important;
}

.square {
    height: 30px;
    width: 30px
}

.custom_margin {
    margin-top: 10px;
}

/*home filter*/

.parent_inline_div .date_period {
    display: flex;
    flex: 0 0 340px;
}

.parent_inline_div {
    display: flex;
    gap: 10px;
    padding-bottom: 110px;
}

.parent_inline_div .date_period div:first-child {
    width: 100%;
}

.parent_inline_div .react-datepicker-wrapper input {
    height: 38px;
    width: 100%;
}

.parent_inline_div .align-bottom {
    display: flex;
    justify-content: center;
    align-items: end;
}

.parent_inline_div .align-bottom button {

    margin-bottom: 0;
}

.home_filter .react-datepicker-wrapper input {
    width: 100%;
    height: 38px;
}

.chart_bottom {
    margin-bottom: 90px
}

.home_filter_parent_tag {
    min-height: 162px;
}

.font-family {
    font-family: Arial !important;
}

.product-div {
    padding-left: 15px;
}

.color_picker {
    height: 50px;
    width: 59px;
}

.inline.active_tabs {
    border: 1px solid #4682b4;
    border-bottom: unset;
    /*opacity: 0.5;*/
    background-color: #344563 !important;
}

.inline {
    padding: 5px 10px;
    font-weight: 600;
    margin-right: 7px;
}

.active_order_quick_filter, .pending_order_quick_filter, .all_order_quick_filter, .pending_order_tax_quick_filter, .pending_order_hoa_quick_filter, .non_retrieved_delivered_order_quick_filter {
    background-color: deepskyblue;
    border-radius: 11px;
    width: 53px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pending_order_quick_filter {
    background-color: orange;

}

.pending_order_tax_quick_filter {
    background-color: steelblue;
}

.pending_order_hoa_quick_filter {
    background-color: green;
}

.non_retrieved_delivered_order_quick_filter {
    background-color: purple;
}


.all_order_quick_filter {
    background-color: gray;
}


.delivery-modal-dialog {
    max-width: 85% !important;
}

.bulk_delete_checkbox {
    position: absolute;
    right: 140px;
    top: 10px;
}

.bulk_delete_checkbox label {
    font-weight: 500;
    color: red;
}

.password_expire_modal {
    max-width: 552px !important;
}

.modal {
    overflow-y: auto;
}

.disabled-fa-button {
    opacity: 0.5;
    pointer-events: none;
}

.position-relative.contact_icon_position .css-1wy0on6 {
    margin-right: 25px;
}

.contact_icon_position .conatct_iconss {
    position: absolute;
    right: 14px;
    top: 7px;
}

.multiple_add_list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.contact_icon_position input {
    padding-right: 80px;
}


.contact_icon_position i {
    cursor: pointer;
}

.multiple_add_list_items {
    display: flex;
    background: rgb(230, 230, 230);
    padding: 3px 10px;
    border-radius: 3px;
    align-items: center;
    gap: 16px;

}

.multiple_add_list_items i {
    font-size: 14px;
}

.multiple_add_list_items span {
    font-size: 13px;
}

p.custom_placeholder {
    position: absolute;
    color: #97a1a1;
    font-size: 16px;
    top: 1px;
}

.position-relative.multiple_add_items {
    border: 1px solid #ddd;
    padding: 5px 10px;
    min-height: 38px;
    padding-right: 50px;
    border-radius: 3px;
}

.form_design p.custom_placeholder {
    position: absolute;
    color: #97a1a1;
    font-size: 16px;
    top: 4px;
}

.multiple_add_icons {
    position: absolute;
    right: 5px;
    top: 5px;
}

.multiple_add_icons i {
    margin-right: 10px;
    cursor: pointer;
}

.form_design .css-t3ipsp-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.valid_password {
    color: green !important;
}

.disable_date_picker {
    background: #dddd !important;;
}


.align_centre_td {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.file_upload_success {
    color: green !important;
}

.file_upload_failure {
    color: red !important;
}


.large_icon {
    font-size: 16px;
}

.editor_nav_bar {
    max-height: 400px;
    overflow: auto;
}

input[type=button] {
    cursor: pointer;
}

button {
    cursor: pointer;
}

.disabled_field {
    background: #dddddd !important;
}

.margin_top {
    margin-top: 15px
}

.timeline-icon {
    position: absolute;
    right: 6px;
    top: 12px;
}

.split-view-container {
    max-width: 100%;
    overflow: auto;
    white-space: nowrap;
}

.diff-view-container {
    background-color: #2f323e;
}

.split-view-container td {
    background: #2f323e !important;
}

.xxl_model {
    max-width: 85% !important;
}

.diff_viewer_label {
    font-size: xx-large;
}

.branch_auditable_btn {
    position: absolute;
    right: 13px;
    top: -63px;
}

.toggle_css {
    font-size: 22px;
    margin-top: -3px;
}

.hoa-fa-icon-color {
    color: #417b40;
}

.top_bar_margin {
    margin-left: 30px;
}

.error_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main_content {
    position: relative;
}

.error_text {
    font-size: 69px;
    font-weight: 900;
}

.btn_style {
    margin-left: 157px !important;
}

.external-div .table_head ul {
    position: relative;
    right: unset !important;
    top: unset;
    margin-left: auto;
    margin-bottom: 10px;
}

/*footer css*/

.titleLogo {
    width: 229px;
    height: 37px;
}

.footer_wrapper {
    width: 100%;
    background: #4682b4;
    padding: 10px 0;
}

.footer_inner_content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer_inner_content a {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    font-family: sans-serif;
}

.footer_inner_content h2 {
    font-weight: 100;
    font-family: sans-serif;
    color: #fff;
    font-size: 15px;
}

.footer_outer {
    width: 50%;
    margin: auto;
}

.logo_container {
    flex-grow: 1;
}

.footer_wrapper ul {
    margin: 0;
}

.default-cursor {
    cursor: default;
}

@media screen and (max-width: 1440px) {
    .footer_outer {
        width: 83%;
        margin: auto;
    }
}

@media screen and (max-width: 1024px) {
    .titleLogo {
        width: 192px;
        height: 37px;
    }

    .footer_outer {
        width: 85%;
        margin: auto;
    }

    .footer_inner_content h2 {
        font-size: 15px;
    }

    .footer_inner_content a {
        font-size: 14px;
    }

}

@media screen and (max-width: 768px) {
    .titleLogo {
        width: 153px;
        height: 33px;
    }

    .footer_outer {
        width: 94%;
        margin: auto;
    }

    .footer_inner_content h2 {
        font-size: 13px;
    }

    .footer_inner_content a {
        font-size: 14px;
    }

}

@media screen and (max-width: 767px) {
    .footer_inner_content a {
        display: flex;
        padding-top: 9px;
    }

    .footer_inner_content {
        padding-bottom: 11px;
    }

    .footer_inner_content h2 {
        text-align: center;
        padding-top: 10px;

    }

    .footer_inner_content {
        display: block;
    }

    .logo_container {
        text-align: center;
    }
}

.react-date-picker {
    position: unset !important;
}

.react-date-picker .react-date-picker__calendar {
    inset: unset !important;
}

.react-calendar {
    margin-top: 42px !important;
}

.hoa_editor_margin {
    margin-bottom: 18rem !important;
}

.outer-div {
    width: 100%;
    max-width: 100%;
    position: relative;
    border: 1px solid #b0aaaa;
    padding-top: 16px;
}

.inner-div {
    position: absolute;
    left: 15px;
    top: -12px;
    background: #ffffff;
    font-size: 15px;
    font-weight: 700;
}

.link_color {
    color: #007bff !important;
}

.parent_account {
    background: #fff;
    margin: 0 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.parent_account .table th {
    border-top: unset;
    white-space: nowrap;
}

.parent_outer table.table.table-hover thead th {
    position: sticky;
    top: 0;
    background: #fff;
}

div.parent_outer {
    max-height: 175px;
}

i {
    cursor: pointer;
}

.notClickable {
    cursor: default !important;
}

.gap {
    gap: 10px;
}

.info {
    color: #17a2b8;
}

ul.table_information li p {
    margin: 0;
}

ul.table_information li strong {
    margin-bottom: 6px;
    display: inline-block;
}

ul.table_information {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 15px;
    gap: 10px;
    flex-wrap: wrap;
}

ul.table_information li {
    width: 48%;
    text-align: left;
}

.revenue_enabled {
    background: black !important;
}

.revenue_enabled_btn {
    padding: 0 10px !important;
}

.max-height {
    height: 537px;
    overflow: scroll;
}

.header-position {
    position: sticky !important;
    top: 0;
}

span.add_link {
    position: absolute;
    width: 15px;
    height: 15px;
    line-height: 10px;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 19%);
    background: green;
    text-align: center;
    right: -4px;
    top: -3px;
}

span.add_link i {
    font-size: 10px;
    color: #fff;
}

span.fa-stack.position-relative i.fas.fa-link {
    font-size: 19px;
    color: #4782b4;
}

.checkbox-margin {
    margin-top: 19px;
}

.header-bar:hover {
    color: white !important;
    text-decoration: none !important;
}

.header-bar {
    color: white !important;
    text-decoration: none !important;
}

.sticky_table th {
    white-space: nowrap;
    top: 0;
}

.sticky_table .table_wrapper {
    position: relative;
    height: 100vh;
}

.sticky_table .table-responsive {
    height: calc(100% - 2px);
}

.table_wrapper tr td:nth-child(3) a {
    color: black;
}

a:hover {
    text-decoration: none !important;
}

.tax_list ul li span {
    word-break: break-all;
}

.qc_section {
    max-height: 220px;
    overflow-y: scroll;
}

.customer_detail_tab {
    position: absolute;
    left: auto;
    right: 10px;
    top: 0;
}

.customer_detail_page {
    position: relative;
}

.Toastify__toast--error {
    width: 640px;
}

button:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.focus, button:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25) !important;
}

.font_size_style {
    font-size: 12px;
}

.assessment_empty_row {
    padding: 9px !important;
    opacity: 0;
}

.default-cursor {
    cursor: default !important;
}

.semicircle-container {
    position: relative;
}

.semicircle-container svg {
    width: 100%;
    height: 100%;

}

.semicircle-container .bar-title {
    text-align: center;
    font-size: 24px;
    width: 200px;
    margin: auto;
}

.semicircle-container .progress-value {
    position: absolute;
    top: 69px;
    left: 50%;
    transform: translate(-50%, 0%);
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    font-size: 30px;
}

