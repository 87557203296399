.first_td {
    width: 30%;
}

.input_height {
    height: 24px
}

.assessment {
    border: 1px solid #2170b0;
}

.section_header {
    padding: 0.3em;
    border: 1px solid #2170b0;
    background: steelblue;
    color: white;
}

.hoa_header_section {
    padding: 0.3em;
    border: 1px solid #407b40;
    background: #5a975a;
    color: white;
}

.hoa_assessment {
    border: 1px solid #407b40;
}

.assessment_info {
    border: 1px solid #7cbdf0;
}

.hoa_assessment_info {
    border: 1px solid #78d478;
}

.account_sub_section {
    padding: 0.3em;
    border: 1px solid #7cbdf0;
    background: #7cbdf0;
    color: white;
    display: flex;
}

.heading {
    display: flex;
}

.heading span {
    margin-left: auto;
}

.account_hoa_sub_section {
    padding: 0.3em;
    border: 1px solid #78d478;
    background: #87c287;
    color: white;
}

.bill_summary {
    padding-left: 35px;
}

.tax-summary-table .delete_icon i {
    position: absolute;
    left: -3px;
    font-size: 15px;
    margin-top: 4px;
}

.tax-summary-table thead {
    background: #7cbdf0;
}

.hoa_assessment thead {
    background: #87c287;
}

.legalDescription {
    border-radius: unset;
    overflow: hidden !important;
}


.row-color {
    background-color: white !important;
}

.scroll {
    max-height: 89px;
    overflow-y: auto;
    overflow-x: hidden;
}
.scroll.certificate_notes_scroll{
    max-height: 168px;
}
.column-width {
    width: 100px;
    height: 185px;
}

.width-check {
    width: 25%;
    margin: 2px;
}

.row-color {
    background-color: white !important;
}

.column-width {
    width: 100px;
    height: 185px;
}

.width-check {
    width: 25%;
    margin: 2px;
}

.collapse_button {
    background: #f9f9f9;
    border: none;
    height: 33px;
}

[data-toggle="Account"] .fa:before {
    content: "\f078";
}

[data-toggle="Account"].collapsed .fa:before {
    content: "\f054";
}

.text-custom {
    margin-left: 2%;
    margin-right: 2%;
}

.text-custom-right {
    margin-left: 30%;

}

.onHover {
    cursor: pointer;
}

.selector3 {
    height: 30px;
}

.selector1 {
    width: 100px;
}

.selector2 {
    height: 29px;
    width: 90px;
}

.selector4 {
    height: 29px;
    width: 86px;
}

.fee_height {
    height: 38px;
}

.note_width {
    width: 300px;
}

.amount_width {
    width: 86px;
}

.jurisdictions_search_bar {
    width: 270px;
    max-width: 270px;
}

.jurisdictions_selector .display_text {
    display: none !important;
}

.jurisdictions_selector .pages_details {
    display: none !important;
}

.selected_jurisdiction {
    background: #7cbdf0 !important;
}

.align-end {
    text-align: end !important;
}

.readonly_bg {
    background: #e9ecef !important;
}

.outline_remove {
    outline: none;
}

.outline_remove1 {
    outline: none;
}

.td_style {
    opacity: 0;
}

.width-data {
    width: 100%;
    height: 38px;
}

/*accounts side bar*/

.accounts_sidebar {
    background: #f9f9f9;
    border-radius: 7px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.search_account input {
    border-radius: 35px;
    border: 1px solid #ddd;
    padding-left: 28px;
}

.search_account {
    padding: 9px;
}

.add_tax {
    padding: 10px;
    background: #4682b4;
}

.add_tax p {
    margin: 0;
    color: #fff;
}

.add_hoa p {
    margin: 0;
    color: #ffffff;
}

.add_tax i {
    color: #fff;
}

.add_hoa i {
    color: #fff;
}

.add_hoa {
    padding: 10px;
    background: #5a975a;
}

.search_account label {
    margin: 0;
    position: relative;
}

.tax_list ul {
    padding: 0;
}

.tax_list ul li {
    padding: 10px;
}

.tax_list p {
    margin: 0;
}

.search_account label i {
    position: absolute;
    left: 10px;
    top: 14px;
}

.search_account {
    background: #e5e5e5;
}

.search_bar_width {
    width: 159px !important;
}

.breadcrumb-data {
    position: absolute;
    right: 13px;
    top: -29px;
}

.breadcrumb-data-hoa {
    position: absolute;
    right: 13px;
    top: 35px;
}

.exemption_data {
    height: 30px;
    padding-top: 0;
}

.weight_data {
    font-weight: 400;
}

.width_data {
    width: 87px;
}

.text_wrap_editor {
    display: block;
    white-space: nowrap;
    width: 15em !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table_width {
    width: 100%;
}

.fa_bg {
    color: steelblue;
}

.fa_hoa_bg {
    color: #448244;
}

div.hoa_cm_btn button {
    background: #5a975a !important;
}

.hoa_color {
    color: #5a975a !important;
}

.btn_bg {
    background: #407b40 !important;
}

.hoa_table thead {
    background: #407b40 !important;
}

.hoa_table .cdp_i.active, .hoa_table .cdp_i:hover {
    background: #407b40 !important;
}

.document-item {
    max-width: 95% !important;
}

.hoa_border_none {
    border: none !important;
}

.invalid_data {
    color: #80bdff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.custom_required {
    background: #fff;
    display: inline-block;
    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
    padding: 10px;
    border: 0.5px solid #838383;
    border-radius: 2px;
    position: absolute;
    bottom: -49px;
    left: 50px;
}

span.custom_required i {
    background: #ffa300;
    width: 26px;
    height: 27px;
    text-align: center;
    line-height: 26px;
    color: #fff;
}

span.custom_required::before {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #838383;
    position: absolute;
    content: "";
    left: 12px;
    top: -5px;
}

span.custom_required::after {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid white;
    position: absolute;
    content: "";
    left: 12px;
    top: -4px;
}

.notifi_width {
    width: 25% !important;
}

.input_weight {
    margin-left: 36px !important;
    width: 45% !important;
}

.hoa_input_bg > div {
    background: #407b40 !important;
}

.bg_warning {
    background: #ffc10796 !important;
    border: none !important;
}


.vendor_task_sidebar {
    background: #f9f9f9;
    border-radius: 7px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.cm-class {
    right: 158px;
}

.vendor-modal-dialog {
    max-width: 50% !important;
}

.editor_disable_btn button {
    background: #969da4 !important;
    color: #fff !important;
}

.vendor-span {
    position: absolute;
    top: 1px;
    font-size: 9px;
}

.span-top-padding {
    padding-top: 10px !important;
}

.badge-primary-data {
    color: #007bff;
}

.badge-danger-data {
    color: #dc3545;

}

.badge-success-data {
    color: #28a745;

}

.badge-secondary-data {
    color: #6c757d;

}

.text-alignment {
    text-align: end;
}


.active_datepicker .react-date-picker__wrapper {
    background: white;
}

.exemption_drop_down {
    overflow-x: unset;
}

.assessment_icons {
    display: flex;
    position: absolute;
    right: 0;
    top: 11px;
}

.assessment_data {
    position: relative;
}

.readonly_icon {
    color: steelblue;
}

.bottom_div {
    position: -webkit-sticky;
    position: sticky;
    margin-top: 20px;
}

.bottom_height {
    top: 84vh;
}

.bottom_height1 {
    top: 57vh;
}

.failed_result {
    border-bottom: 8px solid red;
}

.search_border {
    margin-bottom: 10px;
    border: 2px solid steelblue;
}

.table_height {
    height: 200px;
}

.selected_data {
    background: #aab9c6;
}

.font-family {
    font-family: Arial !important;
}

.table_data.hoa_table .cm_btn {
    background: #407b40 !important;
}

.table_data.hoa_table .cm_btn.cm_bg {
    background: #ddd !important;
}

.place_holder {
    border: magenta 2px solid !important;
}

.doc_delivery_modal {
    max-width: 85% !important;
    height: 85% !important;
}


.doc_delivery_modal iframe {
    min-height: 80vh !important;
}

.margin_data {
    margin-right: 20px !important;
}

.auto-width {
    width: auto !important;
}

span.placeholder:empty::before {
    content: attr(data-placeholder);
    display: inline-block;
}

.center_span_icon {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    right: 17px;
    color: steelblue;
}

.jurisdiction_relative {
    position: relative;
}

.jurisdiction_relative span:first-child {
    padding-right: 31px;
}

.breadcrumb-data .update_status_dropbox.cm_btn {
    padding: 4px 6px;
    margin-right: 4px;
}

.breadcrumb-data .dropdown-button-opener:after {
    height: 24px;
    top: 1px;
}

.hoa_bg {
    background: #5a975a;
}

/* 30 march changes*/
table.hoa_assessment.table.table_data.tax-summary-table {
    table-layout: fixed;
}

table.hoa_assessment.table.table_data.tax-summary-table .form-control {
    font-size: 12px;
    height: 40px;
    padding-left: 5px;
}

findCustomerByIds .react-date-picker__inputGroup {
    min-width: 16px;
    font-size: 12px;
    min-width: 10px;
    width: 10px;
}

.react-date-picker .react-date-picker__inputGroup {
    font-size: 14px;
    min-width: 7px;
}

table.hoa_assessment.table.table_data.tax-summary-table td {
    padding: 11px 3px;
    vertical-align: middle;
}

table.hoa_assessment.table.table_data.tax-summary-table .react-date-picker__button {
    padding: 4px 3px;
    z-index: 2;
}

table.hoa_assessment.table.table_data.tax-summary-table .react-date-picker__button svg {
    font-size: 7px;
    width: 13px;
}

table.hoa_assessment.table.table_data.tax-summary-table div.react-date-picker__wrapper {
    padding-left: 2px;
}

table.hoa_assessment.table.table_data.tax-summary-table thead tr th:first-child {
    width: 30px;
}

table.hoa_assessment.table.table_data.tax-summary-table input.border-0.text-center.outline_remove1.readonly_bg {
    width: 100%;
    font-size: 12px;
    text-align: center;
}

.unset_border_top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: relative;
}

.unset_border_bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.tax_summary_margin {
    margin-bottom: -6px;
}

.unset_border_top::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: -6px;
    background: #f9f9f9;
    width: 100%;
    height: 10px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .assessment_section {
        margin-top: 1rem !important;
    }

}

.exemption_drop_down .table-responsive {
    display: unset;
}

.juris_year {
    display: flex;
    justify-content: space-between;
}
.separation_line{
    background-color: #eaecef;
}