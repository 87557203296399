.order_form input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.order_form input[type="checkbox"] {
    padding: 0;
    width: auto;
}

.width_d {
    width: 79px;
}

.checkbox_input {
    width: 55px !important;
    height: 19px !important;
    vertical-align: middle !important;
    position: relative !important;
    bottom: 1px !important;
}

.checkbox_label {
    padding: 7px;
    display: block;
    border-width: 1px;
}
