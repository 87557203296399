/************************* SideBar CSS Starts Here*******************************************/

/*.dropdown-menu.show {*/
/*    background: transparent;*/
/*    border: transparent;*/
/*}*/


#wrapper {
    transition: all 0.5s ease;
    position: relative;
}

a.active.dropdown-item {
    color: #80d5fa;
}

.sidebar-brand {
    position: absolute;
    top: 0;
    width: 250px;
    text-align: center;
    padding: 20px 0;
}

ul.user_info_inner a:not([href]):not([tabindex]) {
    color: #fff;
    cursor: pointer;
}

.sidebar-brand h2 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
}

.side_nav_ .sidebar-nav {
    position: absolute;
    width: 208px;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #4682b4;
}

.toggled .sidebar-nav {
    position: absolute;
    width: 60px;
    margin: 0;
    padding: 0;
    list-style: none;
}

div.toggled a#navbarDropdown:after {

    border: unset;
}

.sidebar-nav > li {
    text-indent: 10px;
    line-height: 42px;
    margin-bottom: 0.2rem;
}

.sidebar-nav > li a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
}

.header {
    background: #000;
    padding: 7px 0;
}

ul.nav.navbar-nav li a {
    position: relative;
}

ul.nav.navbar-nav a::before {
    position: absolute;
    top: 50%;
    left: -9px;
    width: 9px;
    height: 1px;
    background: #fff;
    content: "";
}

ul.nav.navbar-nav a::after {
    position: absolute;
    background: #fff;
    content: "";
    border-left: 1px Solid #ddd;
    height: 53px;
    width: 0;
    left: -10px;
}

ul.nav.navbar-nav li:nth-child(1) a::after {
    top: 21px;
}

ul.nav.navbar-nav li:last-child a::after {
    bottom: 20px;
}

.panel .drop_down {
    position: relative;
}

.panel .drop_down i.fa.fa-caret-down {
    position: absolute;
    right: 0;
    top: 10px;
}

a.drop_down.collapsed i.fa.fa-caret-down::before {
    content: "\f0da";
}


.panel .collapsed {
    position: relative;
}

.header {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.toggled .fa-angle-double-left:before {
    content: "\f101";
}

.header .navbar-header {
    padding-left: 10px;
}

.navbar-brand i {
    font-size: 20px;
}

.navbar_toggle_btn {
    border: transparent;
    background: transparent;
    color: #fff;
}

.sidebar-nav > li.active > a {
    font-weight: 600;
}

.sidebar-nav > li > a:hover,
.sidebar-nav > li.active > a {
    text-decoration: none;
    color: #fff;
    background: #3e638d;
}

.sidebar-nav > li > a i.fa {
    font-size: 20px;
    width: 60px;
}

#navbar-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}

#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}

#navbar-wrapper .navbar {
    border-width: 0 0 0 0;
    background-color: #eee;
    font-size: 24px;
    margin-bottom: 0;
    border-radius: 0;
}

#navbar-wrapper .navbar a {
    color: #757575;
}

#navbar-wrapper .navbar a:hover {
    color: #144a88;
}

#content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    top: 100px;
}

#wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
}

.logo_outer {
    display: flex;
    flex-grow: 1;
}

.user_in ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 0;
    margin: 0;
}

.user_in {
    margin: 0;
}

ul.user_info_inner li {
    margin: 0;
    list-style: none;
}

ul.user_info_inner li p {
    margin: 0;
    color: #ffffff;
}

ul.user_info_inner li a {
    padding: 10px 20px;
    color: #ffffff;

}
.side_navbar.toggled ul.sidebar-nav {
    overflow: unset;
}
.sidebar-nav {
    height: 100%;
    padding-bottom: 60px !important;
    overflow: scroll;
}
.side_nav_ .system_admin .dropdown-menu.show {
    padding-bottom: 70px;
}
.sidebar-nav li.toggle_btn a:hover {
    background: unset;
}

.sidebar-nav li.toggle_btn a {
    padding: unset;
    margin: 0;
}

li.toggle_btn {
    text-align: right;
    margin: 0;
    padding: 0 12px 0 0;
    position: fixed;
    bottom: 0px;
    transition: all 0.5s ease;
    width: 32px;
    left: 166px;
    z-index: 9;
}

.toggled li.toggle_btn {
    transition: all 0.5s ease;
    width: 60px;
    left: 6px;
}

.side_nav_ {
    transition: all 0.5s ease;
}

.sidebar-nav > li.toggle_btn > a i.fa {
    color: #fff;
    width: unset;
    padding-right: 8px;
}

/*  new css */

#wrapper {

    transition: all 0.5s ease;
    position: relative;
}

#sidebar-wrapper {
    z-index: 1;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    background: #4682b4;
    transition: all 0.5s ease;
    top: 52px;
}

#wrapper.toggled #sidebar-wrapper {

    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
}

.sidebar-brand {
    position: absolute;
    top: 0;
    width: 250px;
    text-align: center;
    padding: 20px 0;
}

.sidebar-brand h2 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    color: #fff;
}


.side_nav_ .dropdown-menu.show {
    height: unset;
    background: transparent;
    border: transparent;


}

.side_nav_ .dropdown-menu.show {
    padding: 0 0 0 27px;
    margin-top: 0;
}

.side_nav_ a#navbarDropdown::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid #fff;
    position: absolute;
    right: 8px;
    top: 18px;
}

.side_nav_ li.nav-item.dropdown.show a#navbarDropdown::after {
    transform: rotate(
            90deg);
}

.side_nav_ .dropdown-menu.show a {
    position: relative;
    padding: 0 14px;
}

.side_nav_ .dropdown-menu.show a::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 42px;
    background: #fff;
    left: 6px;
    top: 2px;
    bottom: 0;
}

.side_nav_ .dropdown-menu.show a:last-child::before {
    height: 29px;
    top: -6px;
}

.side_nav_ .dropdown-menu.show a::after {
    position: absolute;
    content: "";
    background: #fff;
    width: 9px;
    height: 2px;
    left: 8px;
    top: 21px;
}

.sidebar-nav > li {
    text-indent: 10px;
    line-height: 42px;
    margin: 0;
    clear: both;
    z-index: 9;
}

.sidebar-nav > li a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
}

.header {
    background: #000;
    padding: 7px 0;
}

/* div.header img{
    width: 30%;
} */
ul.nav.navbar-nav li a {
    position: relative;
}

ul.nav.navbar-nav a::before {
    position: absolute;
    top: 50%;
    left: -9px;
    width: 9px;
    height: 1px;
    background: #fff;
    content: "";
}

ul.nav.navbar-nav a::after {
    position: absolute;
    background: #fff;
    content: "";
    border-left: 1px Solid #ddd;
    height: 53px;
    width: 0;
    left: -10px;
}

ul.nav.navbar-nav li:nth-child(1) a::after {
    top: 21px;
}

ul.nav.navbar-nav li:last-child a::after {
    bottom: 20px;
}

.panel .drop_down {
    position: relative;
}

.panel .drop_down i.fa.fa-caret-down {
    position: absolute;
    right: 0;
    top: 10px;
}

a.drop_down.collapsed i.fa.fa-caret-down::before {
    content: "\f0da";
}

.panel .collapsed {
    position: relative;
}

.header {
    display: flex;
    align-items: center;
}

.header .navbar-header {
    padding-left: 10px;
}

.navbar-brand i {
    font-size: 20px;
}

.sidebar-nav > li.active > a {
    font-weight: 600;
}

.sidebar-nav > li > a:hover,
.sidebar-nav > li.active a {
    text-decoration: none;
    color: #fff;
    background: #0000005c;
}

a.nav-link.dropdown-toggle {
    padding: 0;
}

.side_nav_ .dropdown-menu.show {
    position: relative !important;
    transform: unset !important;
    width: 100%;
}

.toggled .dropdown-toggle::after {
    border: unset;
}

.toggled li.nav-item.dropdown a:hover + div {
    display: block !important;
}

.toggled .dropdown-menu::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: "";
    left: -10px;
    top: 10px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 8px solid #fff;
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
    filter: drop-shadow(-1px 0px 1px rgba(0, 0, 0, .5));
}

.toggled .panel .drop_down i.fa.fa-caret-down {
    display: none;
}

.sidebar-nav > li > a i.fa {
    font-size: 20px;
    width: 50px;
}

.toggled .sidebar-nav > li span {
    display: none;
    opacity: 0;
}

.toggled .sidebar-nav > li a {
    position: relative;
}

.toggled .sidebar-nav > li {
    display: flex;
    flex-direction: row;
    margin: 0;
}

.dropdown-item:focus,
.dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
    color: #f7f7f7;
    text-decoration: none;
    background: #4682b47d;
}

.toggled li.nav-item.dropdown:hover a + div {
    display: block !important;
    left: 64px !important;
    top: 3px !important;
    background: #ffffff;
    padding: 0;
    -webkit-transform: unset !important;
    transform: unset !important;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);
}

.toggled .sidebar-nav > li a.dropdown-item {
    color: #000;
    padding: 0 10px;
}

.toggled .dropdown-menu.show {
    display: none;
}

.toggled li#dropdown {
    position: relative;
}

#navbar-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}

#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
}

#navbar-wrapper .navbar {
    border-width: 0 0 0 0;
    background-color: #eee;
    font-size: 24px;
    margin-bottom: 0;
    border-radius: 0;
}

#navbar-wrapper .navbar a {
    color: #757575;
}

#navbar-wrapper .navbar a:hover {
    color: #144a88;
}

#content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
    top: 100px;
}

#wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -208px;
}
.blur_image{
    filter: blur(6px);
}
@media (min-width: 992px) {
    #wrapper {
        padding-left: 208px;
    }

    #wrapper.toggled {
        padding-left: 60px;
    }

    #sidebar-wrapper {
        width: 208px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 60px;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width .5s ease-in-out;
        transition: width .5s ease-in-out;
    }

    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -190px;
    }

    #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -190px;
    }

    #navbar-wrapper {
        position: relative;
    }

    #wrapper.toggled {
        padding-left: 60px;
    }

    #content-wrapper {
        position: relative;
        top: 0;
    }

    #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 60px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .side_nav_ {
        padding-left: 208px;
    }

    .side_navbar.toggled {
        padding-left: 60px;
    }

    #sidebar-wrapper {
        width: 60px;
    }

    #wrapper.toggled #navbar-wrapper {
        position: absolute;
        margin-right: -208px;
    }

    #wrapper.toggled #content-wrapper {
        position: absolute;
        margin-right: -208px;
    }

    #navbar-wrapper {
        position: relative;
    }

    #content-wrapper {
        position: relative;
        top: 0;
    }

    #wrapper.toggled #navbar-wrapper,
    #wrapper.toggled #content-wrapper {
        position: relative;
        margin-right: 208px;
    }
}

.search_btn {
    margin-right: 10px;
    border-radius: 9px;
    margin-left: -30px;
    border: none;
    background: transparent;
}

.search_data {
    padding-right: 24px;
}

.search-container:focus {
    outline: none;
    box-shadow: none;
}

.search_bar:focus {
    outline: none;
    box-shadow: none;
}

/*21 jun changes*/
div.toggled .sidebar-nav > li:hover span::before {
    border-bottom: 5px solid transparent;
    border-right: 8px solid #fff;
    border-top: 5px solid transparent;
    content: "";
    -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .5));
    filter: drop-shadow(-1px 0 1px rgba(0, 0, 0, .5));
    height: 10px;
    left: -10px;
    position: absolute;
    top: 10px;
    width: 10px;
}

div.toggled .sidebar-nav > li.nav-item.dropdown:hover span {
    opacity: 0;
}

div.toggled .sidebar-nav > li:hover span {
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0.25rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    list-style: none;
    margin: 0.125rem 0 0;
    min-width: 10rem;
    opacity: 1;
    padding: 2px;
    position: absolute;
    right: -162px;
    text-align: left;
    top: 0;
    width: 110px;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
}

ul.sidebar-nav li a {
    position: relative;
}

.tgx_navbar {
    background-color: #000;
    color: #fff;
    padding: 6px 20px;
    z-index: 3;
    position: fixed;
    width: 100%;
}

a.navbar-brand img {
    width: 207px;
}

.right_nav {
    display: flex;
    margin-left: auto;
}

.right_nav ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 8px;
}

.right_nav ul li {
    list-style: none;
    padding-right: 15px;
    flex-shrink: 0;
}

div.right_nav input.form-control {
    background: #e5ebef;
    border-radius: 10px;
    height: 30px;
    width: 331px;
}

.right_nav .form-inline {
    position: relative;
}

.right_nav  .search_btn i {
    position: absolute;
    right: 5px;
    top: 6px;
    color: #000;
    cursor: pointer;
}

button.navbar-toggler:focus {
    outline: unset;
}

.navbar-dark .navbar-toggler {
    border: unset;
}

@media (max-width: 991px) {
    .right_nav ul li {
        padding-bottom: 16px;
    }

    .right_nav ul {
        display: block;
        padding-top: 17px;
    }

    .right_nav {
        display: block;
        margin-left: auto;
        padding-top: 14px;
    }
}
.cursor-pointer{
    cursor: pointer;
}

.modal-title{
    color: #fff !important;
}


.modal-header .modal_drag_context_icon {
    opacity: 0;
    visibility: hidden;
    transition: ease all .3s;
    -webkit-transition: ease all .3s;
}

.modal-header:hover .modal_drag_context_icon {
    opacity: 1;
    visibility: visible;
    transition: ease all .3s;
    -webkit-transition: ease all .3s;
}
.modal_drag_context_icon::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900; content: "\f0b2";
    font-size:24px
}
.modal-header:hover{
    cursor:pointer;
}

.modal-header {
    background: steelblue;
    color: #fff !important;
}

button.close {
    color: #fff !important;;
    opacity: 1;
}

.content_detail__pagination.cdp a {
    color: #000;
}
@media(max-width:767px){


    .side_navbar.toggled ul {
        background: steelblue;
    }

    .side_navbar.toggled {
        width: 60px;
    }
    #content-wrapper{
        top: 0;
    }
    .side_nav_ .sidebar-nav {
        width: 174px;
    }
    .side_navbar.side_nav_{
        width: 206px;
    }
}
.sidebar-nav::-webkit-scrollbar-thumb {
    background: transparent
}

.sidebar-nav::-webkit-scrollbar {
    width: 4px;
}
.sidebar-nav::-webkit-scrollbar-track {
    backdrop-color:white
}

* {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}
/************************* SideBar CSS Ends Here *******************************************/