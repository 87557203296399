.cdp_i.cdi_disable:hover {
    background: unset;
    color: #4682b4 !important;
}

.display_text {
    font-size: 14px;

}
.dashboard_font_size{
    font-size: 20px;
}